/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        /****************
        **  Initiate Social share buttons
        *****************/
        (function(){
          socializer( '.socializer' );
        }());


        $('.logo-marquee').slick({
          speed: 6000,
          row: 0,
          autoplay: true,
          autoplaySpeed: 0,
          centerMode: true,
          cssEase: 'linear',
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
          pauseOnHover: true,
          infinite: true,
          initialSlide: 1,
          arrows: false,
          buttons: false
        });


        /****************
        **  GRAVITY FORMS disable submit if required fields not filled
        *****************/
        $('form[id^="gform_1"]').on('change', function (e) {
          var $reqd = $(this).find('.gfield_contains_required.gfield_visibility_visible').filter(function (i, c) {
            return []
              .concat($(c).find('input[type="text"], textarea').filter(function (i, fl) { return $(fl).val().length === 0; }).get())
              .concat($(c).find('input[type="checkbox"]').not(':checked').get())
              .length;
          });
          if ($reqd.length) {
            $(this).find('input[type="submit"]').addClass('disabled button-disabled').attr('disabled', true);
          } else {
            $(this).find('input[type="submit"]').removeClass('disabled button-disabled').removeAttr('disabled');
          }
        }).trigger('change');

        $('form[id^="gform_2"]').on('change', function (e) {
          var $reqd = $(this).find('.gfield_contains_required.gfield_visibility_visible').filter(function (i, c) {
            return []
              .concat($(c).find('input[type="text"], textarea').filter(function (i, fl) { return $(fl).val().length === 0; }).get())
              .concat($(c).find('input[type="email"], textarea').filter(function (i, fl) { return $(fl).val().length === 0; }).get())
              .length;
          });
          if ($reqd.length) {
            $(this).find('input[type="submit"]').addClass('disabled button-disabled').attr('disabled', true);
          } else {
            $(this).find('input[type="submit"]').removeClass('disabled button-disabled').removeAttr('disabled');
          }
        }).trigger('change');


        /****************
        **  SVG swap out with PNG on incompatible browsers
        *****************/
        if (!Modernizr.svg) {
          $('img[src*="svg"]').attr('src', function() {
            return $(this).attr('src').replace('.svg', '.png');
          });
        }


        /****************
        **  Carousels
        *****************/
        $('.testimonials-carousel').each(function() {
          $('.testimonials-carousel').slick({
            rows: 0,
            dots: true,
            infinite: true,
            speed: 400,
            fade: true,
            cssEase: 'linear',
            autoplay: true,
            autoplaySpeed: 8000,
            prevArrow: $(this).find(".prev-testimonial"),
            nextArrow: $(this).find(".next-testimonial"),
//             appendDots:$(this).find('.dots')
          });
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
